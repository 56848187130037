import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL, VENUE_GUID } from "../constants";
import dataService from "../apiServices/data.service";
import tokenService from "../apiServices/token.service";
import authService from "../apiServices/auth.service";
import { toast } from "react-toastify";

const initialState = {
  profile: {
    row_guid: null,
    first_name: null,
    last_name: null,
    phone: null,
    email: null,
    gender: null,
    avatar_file_url: null,
  },
  loading: "idle",
  is_login: 0,
  is_access: 0,
  is_loaded: 0,
  token: null,
};

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (email) => {
    const payLoad = {
      Email: email,
      VenueGuid: VENUE_GUID,
    };

    return dataService.findByEmail(payLoad).then((response) => {
      if (response.data.status == "success") {
        return response.data;
      }
    });
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateProfileUrl: (state, action) => {
      if (action.payload !== undefined) {
        state.profile.AvatarFileUrl = action.payload;
      }
    },
    updateLoginStatus: (state, action) => {
      if (action.payload !== undefined) {
        state.is_login = action.payload;
      }
    },
    updateAccessStatus: (state, action) => {
      if (action.payload !== undefined) {
        state.is_access = action.payload;
      }
    },
    updateLoadedStatus: (state, action) => {
      if (action.payload !== undefined) {
        state.is_loaded = action.payload;
      }
    },
    setUserToken: (state, action) => {
      if (action.payload !== undefined) {
        state.token = action.payload;
      }
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
  },

  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      if (state.loading === "pending") {
        if (action.payload.status == "success") {
          state.profile =
            action.payload.response.length > 0
              ? action.payload.response[0]
              : {};
        }

        state.loading = "idle";
      }
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = "Error occured";
      }
    });
  },
});

export const {
  updateProfileUrl,
  updateLoginStatus,
  updateAccessStatus,
  updateLoadedStatus,
  setUserToken,
} = profileSlice.actions;

export default profileSlice.reducer;
