import {
  API_URL,
  API_URL_UPDATED,
  APP_GUID,
  APP_NAME,
  APP_VERSION,
  FLITE_API_AZURE,
  VENUE_GUID,
} from "../constants";
import api from "./api";
import axios from "axios";

class DataService {
  app_info = {
    venue_guid: VENUE_GUID,
    app_name: APP_NAME,
    app_version: APP_VERSION,
    app_guid: APP_GUID,
  };

  /* Employee */

  async getGeneric(payload = {}) {
    return api.post(API_URL + "generic-module/get-generic", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async getEmployee(payload = {}) {
    return api.post(
      API_URL_UPDATED + "employees-module/get-employees",
      payload
    );
  }
  

  async getAvatars(payload = {}) {
    return api.post(
      API_URL_UPDATED + "employees-module/get-avatars",
      payload
    );
  }

  async addEmployee(payload = {}) {
    return api.post(
      API_URL_UPDATED + "employees-module/insert-employee",
      payload
    );
  }

  async updateEmployee(payload = {}) {
    return api.post(
      API_URL_UPDATED + "employees-module/update-employee",
      payload
    );
  }

  async updateEmployeeAvatar(payload = {}) {
    return api.post(
      API_URL_UPDATED + "employees-module/update-employee-avatar",
      payload
    );
  }

  async updateEmployeeAdGuid(payload = {}) {
    return api.post(
      API_URL_UPDATED + "employees-module/update-employee-adguid",
      payload
    );
  }


  async markInactiveUser(payload = {}) {
    return api.post(
      API_URL_UPDATED + "api/update-user/inactive",
      {id:payload}
    );
  }

  async markActiveUser(payload = {}) {
    return api.post(
      API_URL_UPDATED + "api/update-user/active",
      {id:payload}
    );
  }


  async updateGeneric(payload = {}) {
    return api.post(API_URL + "generic-module/update-generic", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async getAppAccess(payload = {}) {
    return api.post(
      API_URL_UPDATED + "appsecurity-module/get-user-access-for-all-apps",
      payload
    );
  }
  async getAppAccessList(payload = {}) {
    return api.post(
      API_URL_UPDATED + "appsecurity-module/get-all-apps",
      payload
    );
  }

  async getAppAccessSingle(payload = {}) {
    return api.post(
      API_URL_UPDATED + "appsecurity-module/get-app-access",
      payload
    );
  }

  async updateAppAccess(payload = {}) {
    return api.post(
      API_URL_UPDATED + "appsecurity-module/insert-or-update-app-access",
      payload
    );
  }
  async findByEmail(payload = {}) {
    return api.post(
      API_URL_UPDATED + "/employees-module/find-by-email",
      payload
    );
  }

  async createUser(payload = {}) {
    return api.post(
      API_URL_UPDATED + "api/create-user",
      {payload:payload}
    );
  }


  async getRoles(payload = {}) {
    return api.post(API_URL_UPDATED + "employees-module/get-roles", payload);
  }
  async getEmployeeByRowGuid(payload = {}) {
    return api.post(
      API_URL_UPDATED + "employees-module/get-employee-details",
      payload
    );
  }

  async listAzureUserActive(payload) {
    return api.get(
      API_URL_UPDATED + "api/azure-user-list/" + payload
    );
  }

  async listAzureUserInActive(payload) {
    return api.get(
      API_URL_UPDATED + "api/azure-user-list/" + payload
    );
  }

  async userAppAccess(payload = {}) {
    return axios.post(
      FLITE_API_AZURE + "api/add-access/",
      { payload },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
        },
      }
    );
  }


  

   async getAppAccessAwait(payload = {}) {
        const res = await api.post(
          API_URL_UPDATED + "appsecurity-module/get-app-access",
          payload
        );
        return res;
      }
}

export default new DataService();
