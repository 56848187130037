
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showAccountSelection:0
};

export const showAccountSelectionSlice = createSlice({
    name: 'showAccountSelection',
    initialState,
    reducers: {
        // actions
        checkViewCount: (state,action) => {
            state.showAccountSelection = action.payload;
        },
       
    
    },

});

export const {checkViewCount} = showAccountSelectionSlice.actions;


export default showAccountSelectionSlice.reducer;
