import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";

const App = lazy(() => import("./App"));

export const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));
if (window.location.hash !== '') {
    // eslint-disable-next-line no-console
    console.log(`hash found: ${window.location.hash}`);
} else {
    root.render(
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <App />
            </Provider>
        </MsalProvider>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
